.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.popup-inner {
  position: relative;
  padding: 32px;
  width: 100%;
  max-width: 640px;
  background-color: white;
}

.popup-inner .close-popup-light {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.popup-inner .close-popup-dark {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.close-popup-light div {
  width: 25px;
  height: 3px;
  background-color: rgb(0, 0, 0, 0.8);
  margin: 5px;
}

.close-popup-dark div {
  width: 25px;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.8);
  margin: 5px;
}

.close-line-1 {
  transform: rotate(-45deg) translate(0px, 6px);
}

.close-line-2 {
  transform: rotate(45deg) translate(0px, -6px);
}

/* Alpha Testing */
.alpha-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.alpha-container h1 {
  margin-bottom: 10px;
}

.alpha-container p {
  font-size: 18px;
  margin-bottom: 5px;
}

.alpha-container ul {
  font-size: 17px;
  width: 90%;
  margin-bottom: 10px;
}

.alpha-container button {
  margin-bottom: 20px;
}

.alpha-container h2 {
  margin-bottom: 10px;
}

.alpha-container input {
  width: 100%;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  font-family: inherit;
  font-size: 16px;
  margin-bottom: 10px;
}

.alpha-container textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  font-family: inherit;
  font-size: 16px;
  box-sizing: border-box;
  resize: none; 
  margin-bottom: 10px;
}

/* Instructions */
.instruction-container {
  display: flex;
  flex-direction: column;
}

.instruction-container h4 {
  margin-bottom: 20px;
}

.instruction-container ul {
  font-size: 17px;
  width: 90%;
  margin-bottom: 20px;
  margin-left: 30px;
}

.instruction-container h3 {
  margin-bottom: 15px;
}

.instruction-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.instruction-row div {
  font-size: 18px;
}

.instruction-container button {
  margin: auto;
}

/* Statistics */
.button-row {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.menu-button-light {
  all: unset;
  cursor: pointer;
  padding: 5px;
  border: 1px solid black;
}
.menu-button-light:hover {
  transform: scale(1.03);
}

.menu-button-dark {
  all: unset;
  cursor: pointer;
  padding: 5px;
  border: 1px solid white;
}
.menu-button-dark:hover {
  transform: scale(1.03);
}

.statistics-container h1 {
  text-align: center;
  margin-bottom: 10px;
}

.statistics-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 10px;
  margin-bottom: 10px;
}

.statistic-box p {
  font-size: 30px;
  text-align: center;
}

/* Leaderboard */
.leader-row {
  display: flex;
}

.leader-name-light {
  font-size: 18px;
  padding: 5px;
  border: 1px solid black;
  flex-grow: 1;
}
.leader-name-dark {
  font-size: 18px;
  padding: 5px;
  border: 1px solid white;
  flex-grow: 1;
}

.leader-points-light {
  font-size: 18px;
  padding: 5px;
  border: 1px solid black;
  flex-grow: 0;
  width: 15%;
}
.leader-points-dark {
  font-size: 18px;
  padding: 5px;
  border: 1px solid white;
  flex-grow: 0;
  width: 15%;
}

/* Settings */
.settings-container h1 {
  margin-bottom: 30px;
}
