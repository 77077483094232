.search-container {
  margin-top: 20px;
  width: 325px;
  display: flex;
  flex-direction: column;
}

.dropdown-wrapper {
  position: relative;
  width: 0;
  height: 0;
}

.dropdown-light {
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  border-radius: 8px;

  transform: scaleX(-1);
  transform: scaleY(1);
  position: absolute;
  bottom: 0px;
  width: 237px;
}

.dropdown-dark {
  background-color: rgb(21, 21, 21);
  display: flex;
  flex-direction: column;
  border-radius: 8px;

  transform: scaleX(-1);
  transform: scaleY(1);
  position: absolute;
  bottom: 0px;
  width: 237px;
}

.dropdown:empty {
  border: none;
}

.dropdown-row {
  cursor: pointer;
  text-align: start;
  margin: 2px 0;
  font-family: inherit;
}
.dropdown-row:hover {
  font-weight: bold;
}

.search-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.search-inner input {
  width: 237px;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  font-family: inherit;
  font-size: 16px;
}
