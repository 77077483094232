.form {
  width: 100%;
}

.form h3 {
  font-size: 25px;
  margin-bottom: 15px;
}

.form input {
  width: 100%;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: inherit;
}

.logout-buttons button {
  margin-right: 10px;
  margin-top: 10px;
}

.login-container h1 {
  margin-bottom: 20px;
}

.login-container button {
  margin-bottom: 10px;
}

.input-error {
  border: 2px solid #ff4444 !important;
}

.error-message {
  color: #ff4444;
  font-size: 0.8rem;
  margin-top: 2px;
  margin-bottom: 8px;
  min-height: 1rem;
}

.label {
  display: flex;
  flex-direction: row;
}
.label div {
  margin-right: 5px;
}
