* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.light {
  background-color: whitesmoke;
}

.dark {
  background-color: rgb(28, 25, 25);
  color: white;
}

/* HEADER */
header {
  width: 100%;
  text-align: center;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.747);
}

.filler {
  width: 17%;
}

header button {
  all: unset;
  cursor: pointer;
  font-size: 30px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

header p {
  margin-left: 5px;
  font-size: 20px;
}

.header-light {
  background-color: white;
}

.header-dark {
  background-color: rgb(39, 34, 34);
}

.menu-buttons {
  display: flex;
  flex-direction: row;
}

/* GRAPH */
.graph {
  height: 250px;
  width: 700px;
  background-color: rgba(0, 0, 0, 0);
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

/* BOARD */
.game {
  width: 100vw;
  display: flex;
  align-items: center;
  padding-top: 70px;
  flex-direction: column;
}

.board {
  width: 1000px;
  height: 350px;
  display: flex;
  flex-direction: column;
}

.row {
  flex: 33%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 5px;
}

.row h3 {
  text-align: center;
  width: 150px;
}

.metric {
  flex: 33%;
  height: 100%;
  border: 1px solid grey;
  margin: 5px;
  display: grid;
  place-items: center;
  font-size: 20px;
  font-weight: bolder;
  font-family: Arial, Helvetica, sans-serif;
}

.metric-sector {
  flex: 33%;
  height: 100%;
  border: 1px solid grey;
  margin: 5px;
  display: grid;
  place-items: center;
  font-size: 14px;
  font-weight: bolder;
  font-family: Arial, Helvetica, sans-serif;
}

/* Fancy buttons */
.fancy-button-light {
  padding: 10px 20px;
  border: 1px solid #000;
  border-radius: 5px;
  background: black;
  color: white;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  transition-duration: 0.5s;
}
.fancy-button-light:hover {
  transform: scale(0.97);
  background: rgba(0, 0, 0, 0.8);
  transition-duration: 0.5s;
}
.fancy-button-light:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.fancy-button-dark {
  padding: 10px 20px;
  border: 1px solid #000;
  border-radius: 5px;
  background: white;
  color: black;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  transition-duration: 0.5s;
}
.fancy-button-dark:hover {
  transform: scale(0.97);
  background: whitesmoke;
  transition-duration: 0.5s;
}
.fancy-button-dark:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}


.underline-btn {
  all: unset;
  cursor: pointer;
  display: inline-block;
  padding-bottom: 0.25rem;
  position: relative;
}
.underline-btn::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: rgb(0, 0, 0, 0.8);
  transition: 0.25s ease-out;
}
.underline-btn:hover::before {
  width: 100%;
}
